
import '../scss/main/theme.scss';

import { invokeKeenSlider } from "@nf/keen-slider-bridge";

import invokeBootstrap from "./plugins/bootstrap";
import invokeLightbox from "./plugins/lightbox";
import invokeMagicSlider from "./plugins/magic_slider";
///@ts-ignore
import invokePagination from "./plugins/pagination";
import invokeNavbar from "./plugins/navbar";
import invokeScrollTop from "./plugins/scroll-top";

// Invoke NFCore Plugins
invokeBootstrap();
invokeKeenSlider();
invokeMagicSlider();
invokeLightbox();
invokeNavbar();
invokePagination();
invokeScrollTop();
