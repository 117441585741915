import ready from "../supports/ready";
import query from "../supports/query";
import select from "../supports/select";

/**
 * Export Pagination Plugin
 * @returns 
 */
export default async function() {
    await ready();

    const list = select('.news-list-view');
    const navi = select('.news-list-pagination');
    if (!list || list.children.length < 3 || !navi) {
        return;
    }

    // Select Children
    const children = Array.from(list.children);
    children.map((el, idx) => {
        if (idx > 2) {
            el.classList.add('d-none');
        }
    });

    // Select Navi
    const emptyBefore = document.createElement('li');
    emptyBefore.className = 'page-item page-item-dots dots-before';
    emptyBefore.innerHTML = '<span class="page-link disabled">...</span>';
    const emptyAfter = document.createElement('li');
    emptyAfter.className = 'page-item page-item-dots dots-after';
    emptyAfter.innerHTML = '<span class="page-link disabled">...</span>';
    function handleNaviItems() {
        const items = query('.page-item:not(.page-item-dots)', navi);
        const activeIdx = items.findIndex(el => el.classList.contains('active'));
        
        let count = 0;
        let min = items.length - activeIdx < 5 ? items.length-5 : Math.max(activeIdx - 2, 0);
        let max = activeIdx + 4;
        items.map((el, idx) => {
            if (idx == 0 || idx == items.length-1 || (idx >= min && idx <= max && count < 6)) {
                el.classList.remove('d-none');
                count++;
            } else {
                el.classList.add('d-none');
            }
        });

        const firstHidden = select('.page-item.d-none:not(.page-item-dots)', navi);
        if (firstHidden) {
            firstHidden.parentElement.insertBefore(emptyBefore, firstHidden);

            const emptyIdx = query('.page-item', navi).findIndex(el => el.classList.contains('page-item-dots'));
            if (emptyIdx < activeIdx) {
                const firstHiddenAfterActive = select('.page-item.active ~ .page-item.d-none:not(.page-item-dots)', navi);
                if (firstHiddenAfterActive) {
                    firstHiddenAfterActive.parentElement.insertBefore(emptyAfter, firstHiddenAfterActive);
                } else {
                    emptyAfter.remove();
                }
            } else {
                emptyAfter.remove();
            }
        } else {
            emptyBefore.remove();
        }
    }
    handleNaviItems();
    navi.classList.remove('d-none');

    // Toggle Items
    function toggleItems(el: HTMLElement) {
        const active = select('.page-item.active:not(.page-item-dots)', navi);
        if (active == el) {
            return;
        }
        active.classList.remove('active');
        el.classList.add('active');
        handleNaviItems();

        const page = parseInt(el.dataset.page);
        const starts = page * 3;

        let count = 0;
        children.map((el, idx) => {
            if (idx < starts) {
                el.classList.add('d-none');
                return;
            }

            if (count > 2) {
                el.classList.add('d-none');
                return;
            }

            el.classList.remove('d-none');
            count++;
        });
    }
    const items = query('.page-item:not(.page-item-dots)', navi) as HTMLElement[];
    items.map((el, idx) => {
        el.dataset.page = idx.toString();
        el.addEventListener('click', () => toggleItems(el));
    })
}
