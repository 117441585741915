
import { KeenSliderInstance } from "keen-slider";
import ready from "../supports/ready";
import select from "../supports/select";

/**
 * Export ScrollTop Plugin
 * @returns 
 */
export default async function () {
    await ready();

    let magicSlider = select('.keen-slider-magic');
    if (!magicSlider) {
        return;
    }

    let keenSlider = magicSlider['__keen_slider'] as KeenSliderInstance;


    let updateClassName = (ev) => {
        let cur = ev.track.details.rel;
        Array.from(keenSlider.slides).map(el => el.classList.remove('is-current'));
        keenSlider.slides[cur].classList.add('is-current');

        let controlImages = keenSlider.container.querySelectorAll('[data-slide-index]') as NodeListOf<HTMLImageElement>;
        Array.from(controlImages, img => {
            let visible = false;
            let idx = parseInt(img.dataset.slideIndex);
            let act = img.parentElement.dataset.keenControl;

            if (act === 'prev') {
                visible = (cur == 0 && idx == keenSlider.slides.length-1) || cur-1 == idx;
            } else if (act === 'next') {
                visible = (cur == keenSlider.slides.length-1 && idx == 0) || cur+1 == idx;
            } else if (act === 'cur') {
                visible = cur == idx;
            }
            
            if (visible) {
                img.classList.add('is-visible');
            } else {
                img.classList.remove('is-visible');
            }
        });
    }

    let updateHeight = () => {
        let content = keenSlider.slides[keenSlider.track.details.rel].querySelector('.slide-content') as HTMLElement;
        keenSlider.container.style.height = content.offsetHeight+320 + "px";
    };
    
    keenSlider.on('updated', updateClassName);
    keenSlider.on('slideChanged', updateClassName);
    keenSlider.on('slideChanged', updateHeight);
    keenSlider.update();

    setTimeout(() => {
        updateHeight();
    }, 150);
};
